import React, { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import { BubblingTooltip } from '@/components/ui'
import RecommendedPriceNotice from '@/pages/CulturesSaleLots/components/CultureSaleRequest/RecommendedPriceNotice'
import RegionsDeclinations from '@/pages/CulturesSaleLots/components/CultureSaleRequest/RegionsDeclinations'
import { useLocation } from '@/router/hooks'
import { ECultureId } from '@/types'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { formatLocalizedString } from '@/utils/formatUtils'
import { useScrollDirection } from '@/utils/hooks'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { useHarvestSaleLotsPageParams } from '@/utils/outputs/useHarvestSaleLotsPageParams'
import { IPricePredictionWidgetProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const PricePredictionWidget = (props: IPricePredictionWidgetProps) => {
  const { values } = useFormState()
  const { cultureId } = useHarvestSaleLotsPageParams()
  const { isDesktop } = useDeviceType()
  const { deliveryOptions } = values || {}
  const { deliveryBasisId } = deliveryOptions || []
  const isFCA = deliveryBasisId === EDeliveryBasisVariantId.pickup
  const buyersAndPrices = props.buyersAndPrices

  const { scrollClassName, setDirection } = useScrollDirection({
    className: 'culture-sale-request-price-prediction-top',
    defaultDirection: 'up',
    minThrottle: 270,
  })
  const { pathname } = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => setDirection('down'), 250)

    return () => clearTimeout(timeout)
    //eslint-disable-next-line
  }, [pathname])

  const minPrice = buyersAndPrices?.minPrice?.value
  const maxPrice = buyersAndPrices?.maxPrice?.value

  const withPrice = Boolean(minPrice && maxPrice) && minPrice !== Infinity && maxPrice !== Infinity
  const recommendedPrice = Boolean(minPrice && maxPrice) && minPrice === maxPrice
  const isCurrentRegion = values.regionCode || deliveryOptions.storeId
  const buyersInCurrentRegion = buyersAndPrices?.buyersInGivenRegionPresent === true

  const regionsName = <RegionsDeclinations regions={buyersAndPrices?.regionsNumber} />

  return (
    <div className={`culture-sale-request-price-prediction-top ${scrollClassName}`}>
      <div className="culture-sale-request-price-prediction-right-wrapper">
        <div id="top-notice-title" className="space-holder8 color_pale-black text_small">
          {messages.cultureSale.topNotice.title}
        </div>
        <div id="top-notice-description" className="space-holder16 text_right-only-md text_small-md h5-from-md">
          {formatLocalizedString(messages.cultureSale.buyersAllRegion, { regions: buyersAndPrices?.regionsNumber, regionsName })}
        </div>
        {!withPrice && !recommendedPrice && isDesktop && isFCA && (
          <div className="color_pale-black text_small">{messages.cultureSale.topNotice.noPrice.advice}</div>
        )}
        {withPrice && !recommendedPrice && isFCA && (
          <>
            <div className="top-notice-with-price space-holder8">
              <span className="color_pale-black text_small space-holder8">{messages.cultureSale.topNotice.price}</span>
              <BubblingTooltip
                content={
                  <RecommendedPriceNotice
                    isAddressNotChosen={
                      (!isCurrentRegion && !values.quality?.proteinFrom) ||
                      (!buyersAndPrices?.buyersInGivenRegionPresent && !values.quality?.proteinFrom) ||
                      (cultureId === ECultureId.WHEAT && !values.quality?.proteinFrom)
                    }
                    isActualPrice={
                      (recommendedPrice && buyersInCurrentRegion && cultureId !== ECultureId.WHEAT) ||
                      (values.quality?.proteinFrom && recommendedPrice)
                    }
                    isNoActualPrice={
                      (buyersInCurrentRegion && !recommendedPrice && cultureId !== ECultureId.WHEAT) ||
                      (values.quality?.proteinFrom && !recommendedPrice)
                    }
                  />
                }
              >
                <span>
                  <Icon name={EIconName.QuestFill} style={{ opacity: '0.2' }} />
                </span>
              </BubblingTooltip>
            </div>
            <div id="middle-price" className="text_right-only-md space-holder16">
              <h5>{formatLocalizedString(messages.cultureSale.minAndmaxPrices, { minPrice, maxPrice })}</h5>
            </div>

            {isDesktop && <div className="color_pale-black text_small">{messages.cultureSale.topNotice.advice}</div>}
          </>
        )}
        {recommendedPrice && isFCA && (
          <>
            <div className="top-notice-with-price">
              <div className=" color_pale-black text_small">{messages.cultureSale.topNotice.price}</div>
              <BubblingTooltip
                content={
                  <RecommendedPriceNotice
                    isAddressNotChosen={
                      !isCurrentRegion ||
                      !buyersAndPrices?.buyersInGivenRegionPresent ||
                      (cultureId === ECultureId.WHEAT && !values.quality?.proteinFrom)
                    }
                    isActualPrice={
                      (recommendedPrice && buyersInCurrentRegion && cultureId !== ECultureId.WHEAT) ||
                      (values.quality?.proteinFrom && recommendedPrice)
                    }
                    isNoActualPrice={
                      (buyersInCurrentRegion && !recommendedPrice && cultureId !== ECultureId.WHEAT) ||
                      (values.quality?.proteinFrom && !recommendedPrice)
                    }
                  />
                }
              >
                <span>
                  <Icon name={EIconName.QuestFill} style={{ opacity: '0.2' }} />
                </span>
              </BubblingTooltip>
            </div>
            <div className="text_right-only-md">
              <h5>{formatLocalizedString(messages.cultureSale.currentPrice, { price: maxPrice })}</h5>
            </div>

            {isDesktop && <div className="color_pale-black text_small">{messages.cultureSale.topNotice.advice}</div>}
          </>
        )}
      </div>
    </div>
  )
}

export default PricePredictionWidget
