import React from 'react'
import { IRecommendedPriceNoticeProps } from '@/pages/CulturesSaleLots/components/CultureSaleRequest/interfaces'
import { messages } from './messages'

const RecommendedPriceNotice = (props: IRecommendedPriceNoticeProps) => {
  const { isAddressNotChosen, isActualPrice, isNoActualPrice } = props

  return (
    <>
      {isAddressNotChosen && <p>{messages.cultureSale.addressNotChosen}</p>}
      {isActualPrice && <p>{messages.cultureSale.isActualPrice}</p>}
      {isNoActualPrice && <p>{messages.cultureSale.isNoActualPrice}</p>}
    </>
  )
}
export default RecommendedPriceNotice
