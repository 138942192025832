import React, { useContext } from 'react'
import { StoreInput } from '@/components/composed'
import { HarvestSaleLotsContext } from '@/pages/HarvestSaleLots/constants'
import { IStoreLocation } from '@/types'
import { getStoreValidator } from '@/utils/validators'
import { messages } from './messages'
import './styles.scss'

const StoreFilter: React.FC = () => {
  const { saveFilterValues, formValues } = useContext(HarvestSaleLotsContext)

  const onStoreChange = ({ id: locationId }: IStoreLocation) => {
    saveFilterValues({ ...formValues, store: locationId, sortBy: undefined })
  }

  return (
    <div className="space-holder-top24 cultures-sales-lots-store-filter-wrapper ">
      <StoreInput
        name="store"
        label={messages.placeholder}
        dropdownProps={{
          dropdownModifiers: ['black'],
          className: 'harvest-sale-dropdown-filters__dropdown cultures-sales-lots-store-filter',
          customValidator: getStoreValidator(false),
          shouldBeTouchedBeforeError: false,
        }}
        onStoreChange={onStoreChange}
        onAddNewLocation={onStoreChange}
        position="page"
      />
    </div>
  )
}

export default StoreFilter
