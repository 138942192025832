import React from 'react'
import Lot from '@/components/composed/harvestSaleLots/Lot/Lot'
import { pushHarvestGtmEvent } from '@/logic/metrika/harvest/gtmEvents'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { ITemplateProps } from './interfaces'
import './styles.scss'

const Template: React.FC<ITemplateProps> = props => {
  const { template, openRequestingHarvestSaleModal, isAgrarian, isMobileDevice, isDeviceDesktop } = props
  const { culture } = template
  const { id: cultureId } = culture
  const { makeAuthorizedAction } = useActionWithAuthorization(() => {
    pushHarvestGtmEvent({
      items: [template],
      pagePart: 'content',
      blockName: 'blockListingOutputs',
      eventCategory: 'ecommerce',
      eventAction: 'click',
      eventLabel: 'buttonSubmitApplication',
      eventStatus: 'success',
      ecommerceType: 'add',
      eventEcommerce: 'addToCart',
      eventNonInteraction: '0',
      itemContext: 'listingLotsOutputs',
      actionField: {
        list: 'listingLotsOutputs',
      },
      productType: 'template',
    })
    openRequestingHarvestSaleModal({ template, initialCultureId: cultureId })
  }, isAgrarian)

  return (
    <section className="culture-sale-lot row row_column" onClick={!isDeviceDesktop ? makeAuthorizedAction : undefined}>
      <Lot
        isAgrarian={isAgrarian}
        isMobileDevice={isMobileDevice}
        template={template}
        openRequestingHarvestSaleModal={isDeviceDesktop ? makeAuthorizedAction : undefined}
        page={'culture-sale-lot'}
      />
    </section>
  )
}

export default Template
